import axios from "@/libs/api.request";
import { getToken } from "@/libs/util";

export const requestApi = ({ api, method, data, params }) => {
  //console.log(api);
  return axios.request({
    //url: api != undefined ? api : "Core/RequestApi",
    url: "Core/RequestApi",
    //url: "penaltyClientCoreConnector",
    params: params,
    data: {
      api: api,
      method: method,
      token: getToken(),
      data: data
    },
    method: "post"
  });
};