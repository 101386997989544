//import Vue from "vue";

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Element from 'element-ui'
// Vue.use(Element)

//import './assets/scss/dashforge.scss';
import './assets/css/dashforge.css';

const moment = require('moment')
require('moment/locale/mn')

// createApp.use(require('vue-moment'), {
//   moment
// })

createApp(App).use(store).use(router).mount('#app')