<template>
  <div >
    
  </div>
</template>

<script>
import { request } from '@/api/all';
import { mapActions } from "vuex";
import { setToken } from "@/libs/util";


export default {
  name: 'Home',
  data(){
    return{
      regNo: '',
      lastDigits: '',
      notMyRegisterModal:{
        modal: false,
        plateNo: ''
      }
    }
  },
  mounted() {
    //console.log(this.$route.params.id);
    this.handleLoading({ isLoading: true });
    request({
      api: { method: 'post', url: 'User/LoginApi'  },
      params:{
        user: 'tads.client',
        password: 'Zxasqw!2',
        key: 'aal8X8oVMkW9CVK0bwCS3g'
      },
      store: this.$store
    }).then(res => {
      this.handleLoading({ isLoading: false });
      if (res !== false) {
        setToken(res.token);
        this.$router.push({name: 'request'});
        console.log(res);
      }
    });
  },
  methods:{
    ...mapActions(["handleLoading"]),
  }
}
</script>
