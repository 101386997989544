import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/r',
    name: 'request',
    component: function () {
      return import('../views/request.vue')
    }
  },
  {
    path: '/l/:id/:p1',
    name: 'list',
    component: function () {
      return import('../views/list.vue')
    }
  },
  {
    path: '/c/:id',
    name: 'scan',
    component: function () {
      return import('../views/scan.vue')
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  //mode: 'history',
  routes
})

export default router
