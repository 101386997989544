import { createStore } from 'vuex'

export default createStore({
  state: {
    loading: false,
  },
  mutations: {
    setLoading(state, showLoader) {
      state.loading = showLoader;
    },
  },
  actions: {
    handleLoading({
      commit
    }, {
      isLoading
    }) {
      commit("setLoading", isLoading);
    },
  },
  modules: {
  }
})
